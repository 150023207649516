<template>
  <v-row>
    <v-col>
      redirect...
    </v-col>
  </v-row>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      lineUserId: "",
      lineProfilePicture: ""
    };
  },
  created() {
    this.getLineCode();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getLineCode() {
      // const lineapi = "https://api.line.me/oauth2/v2.1/token";
      // const header = { "Content-Type": "application/x-www-form-urlencoded" };
      const data = qs.stringify({
        grant_type: "authorization_code",
        code: this.$route.query.code,
        redirect_uri: "http://localhost:8081/line_callback",
        client_id: "1655629601",
        client_secret: "8395546713b608d69c1c236fe95154c2"
      });
      var config = {
        method: "post",
        url: "https://api.line.me/oauth2/v2.1/token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };
      this.axios(config)
        .then(res => {
          this.getLineProfile(res.data.access_token);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getLineProfile(token) {
      var config = {
        method: "get",
        url: "https://api.line.me/v2/profile",
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      this.axios(config)
        .then(res => {
          this.lineUserId = res.data.userId;
          this.lineProfilePicture = res.data.pictureUrl;
          this.$router.push({
            name: "AgentRegister",
            params: {
              lineUserId: res.data.userId,
              lineProfilePicture: res.data.pictureUrl,
              lineDisplayName: res.data.displayName
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
